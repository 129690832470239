import React, { useState, useEffect, useRef } from 'react';
import { Prompt } from 'react-router';
import moment from 'moment';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import { graphqlUrl, endPoint } from 'utils/config';
import Files from 'react-butterfiles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import GridContainer from 'components/Grid/GridContainer.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import FunMap from './FunMap';
import Button from 'components/CustomButtons/Button.js';
import Modal from 'commonComponents/modal/Modal';
import { login } from 'utils/auth';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import GridItem from 'components/Grid/GridItem.js';
import SelectSearch from 'react-select-search';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Datetime from 'react-datetime';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import style from './AddShop.module.scss';

const GET_LOCATION = gql`
	query getLocationByAddress($address: String!) {
		getLocationByAddress(address: $address) {
			lat
			long
		}
	}
`;

const GET_TOKEN = gql`
	query getNewAccessTokenByAdmin($refreshToken: String!) {
		getNewAccessTokenByAdmin(refreshToken: $refreshToken) {
			token {
				refreshToken
				accessToken
			}
		}
	}
`;

const UPLOAD_FILE = gql`
	mutation uploadFile($file: Upload!, $folderName: String!) {
		uploadFile(data: { file: $file, folderName: $folderName }) {
			url
		}
	}
`;

const ADD_SHOP_BY_ADMIN = gql`
	mutation createShopByAdmin(
		$shopAdminId: ID!
		$budget: Budget
		$active: Boolean
		$rootCategory: ID
		$name: String
		$preparingTime: Int!
		$address: String
		$phoneNumbers: [String!]!
		$location: ShopLocationInput!
		$origin: String
		$categories: [ID]
		$description: String
		$workingHoursInMinutes: [workingHoursInMinutesInput]
		$notWorkingDays: [notWorkingDaysInput]
		$bannerUrl: String
		$logoUrl: String
	) {
		createShopByAdmin(
			shopAdminId: $shopAdminId
			input: {
				budget: $budget
				active: $active
				name: $name
				preparingTime: $preparingTime
				rootCategory: $rootCategory
				address: $address
				categories: $categories
				phoneNumbers: $phoneNumbers
				location: $location
				origin: $origin
				description: $description
				workingHoursInMinutes: $workingHoursInMinutes
				notWorkingDays: $notWorkingDays
				bannerUrl: $bannerUrl
				logoUrl: $logoUrl
			}
		) {
			_id
			state
			name
		}
	}
`;

const ADD_SHOP_BY_SHOP_ADMIN = gql`
	mutation createShopByShopAdmin(
		$budget: Budget
		$active: Boolean
		$rootCategory: ID
		$name: String
		$preparingTime: Int!
		$address: String
		$phoneNumbers: [String!]!
		$location: ShopLocationInput!
		$origin: String
		$categories: [ID]
		$description: String
		$workingHoursInMinutes: [workingHoursInMinutesInput]
		$notWorkingDays: [notWorkingDaysInput]
		$bannerUrl: String
		$logoUrl: String
	) {
		createShopByShopAdmin(
			shopData: {
				budget: $budget
				active: $active
				name: $name
				preparingTime: $preparingTime
				categories: $categories
				rootCategory: $rootCategory
				address: $address
				phoneNumbers: $phoneNumbers
				location: $location
				origin: $origin
				description: $description
				workingHoursInMinutes: $workingHoursInMinutes
				notWorkingDays: $notWorkingDays
				bannerUrl: $bannerUrl
				logoUrl: $logoUrl
			}
		) {
			_id
			state
			name
			active
			rootCategory {
				_id
				title
			}
		}
	}
`;

const UPDATE_SHOP_BY_SHOP_ADMIN = gql`
	mutation updateShopByShopAdmin(
		$budget: Budget
		$active: Boolean
		$rootCategory: ID
		$name: String
		$preparingTime: Int!
		$address: String
		$categories: [ID]
		$phoneNumbers: [String!]!
		$location: ShopLocationInput!
		$origin: String
		$description: String
		$workingHoursInMinutes: [workingHoursInMinutesInput]
		$notWorkingDays: [notWorkingDaysInput]
		$bannerUrl: String
		$logoUrl: String
	) {
		updateShopByShopAdmin(
			data: {
				budget: $budget
				active: $active
				name: $name
				rootCategory: $rootCategory
				address: $address
				preparingTime: $preparingTime
				phoneNumbers: $phoneNumbers
				location: $location
				origin: $origin
				categories: $categories
				description: $description
				workingHoursInMinutes: $workingHoursInMinutes
				notWorkingDays: $notWorkingDays
				bannerUrl: $bannerUrl
				logoUrl: $logoUrl
			}
		) {
			_id
			origin
		}
	}
`;

const UPDATE_SHOP_AFTER_REJECTION = gql`
	mutation updateShopAfterRejectedByShopAdmin(
		$budget: Budget
		$active: Boolean
		$rootCategory: ID
		$name: String
		$preparingTime: Int!
		$address: String
		$categories: [ID]
		$phoneNumbers: [String!]!
		$location: ShopLocationInput!
		$origin: String
		$description: String
		$workingHoursInMinutes: [workingHoursInMinutesInput]
		$notWorkingDays: [notWorkingDaysInput]
		$bannerUrl: String
		$logoUrl: String
	) {
		updateShopAfterRejectedByShopAdmin(
			data: {
				budget: $budget
				active: $active
				name: $name
				rootCategory: $rootCategory
				address: $address
				preparingTime: $preparingTime
				phoneNumbers: $phoneNumbers
				location: $location
				origin: $origin
				categories: $categories
				description: $description
				workingHoursInMinutes: $workingHoursInMinutes
				notWorkingDays: $notWorkingDays
				bannerUrl: $bannerUrl
				logoUrl: $logoUrl
			}
		) {
			_id
			origin

			_id
			rootCategory {
				_id
				title
			}
		}
	}
`;

const GET_CATEGORIES = gql`
	query getCategories($parent: ID) {
		getCategories(filters: { parent: $parent }) {
			_id
			title
			parent {
				title
			}
		}
	}
`;

const GET_ATTRIBUTE_GROUPS = gql`
	query getAttributeGroups($rootCategory: ID) {
		getAttributeGroups(filters: { rootCategory: $rootCategory }) {
			_id
			name
			attributes {
				_id
				name
			}
			rootCategory {
				_id
			}
		}
	}
`;

const GET_SHOP_ADMINS = gql`
	query getAdminsByAdmin($type: String, $fullName: String) {
		getAdminsByAdmin(filters: { type: $type, fullName: $fullName }) {
			_id
			fullName
			phoneNumber
			shop {
				_id
			}
		}
	}
`;

const AddShop = (props) => {
	const topRef = useRef(null);
	const downRef = useRef(null);

	const orangeTheme = {
		checkboxColor: 'orange',
		primaryColor: 'warning',
	};
	const greenTheme = {
		checkboxColor: '#8bc34a',
		primaryColor: 'success',
	};
	const grayTheme = {
		checkboxColor: 'default',
		primaryColor: 'yellow',
	};

	let theme = orangeTheme;
	if (props.theme === 'Purchase') {
		theme = greenTheme;
	} else if (props.theme === 'Restaurant') {
		theme = orangeTheme;
	} else if (props.theme === 'superAdmin') {
		theme = grayTheme;
	}

	const [showRejectionMessage, setShowRejectionMessage] = useState(false);
	const [showRejectionMessageText, setShowRejectionMessageText] = useState(false);

	useEffect(() => {
		if (props.editAfterRejection && window.localStorage.getItem('rejectionMessage')) {
			setShowRejectionMessage(true);
			setShowRejectionMessageText(window.localStorage.getItem('rejectionMessage'));
		}
	}, [,]);

	const [markerPosition, setmarkerPosition] = useState(
		props.shop && props.shop.location && props.shop.location.coordinates
			? {
					lat: props.shop.location.coordinates[1],
					lng: props.shop.location.coordinates[0],
			  }
			: {
					lat: 43.6532,
					lng: -79.3832,
			  }
	);

	const [showLocationInputs, setShowLocationInputs] = useState(false);

	useEffect(() => {
		if (!(markerPosition.lat === 43.6532 && markerPosition.lng === -79.3832)) {
			setShowLocationInputs(true);
		}
	}, [markerPosition]);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setmarkerPosition({ lat: position.coords.latitude, lng: position.coords.longitude });
					console.log(position.coords);
				},
				(error) => console.log(error)
			);
		} else {
			console.log('navigator not available');
		}
	}, [,]);

	const [searchAddress, setSearchAddress] = useState('');

	const [getLocationByAddress, { loading: locationLoading, error: locationError, data: locationData }] = useLazyQuery(
		GET_LOCATION
	);

	useEffect(() => {
		if (locationData) {
			// console.log(locationData);
			setmarkerPosition({
				lat: locationData.getLocationByAddress.lat,
				lng: locationData.getLocationByAddress.long,
			});
		}
		if (locationError) {
			console.log(locationError);
		}
	}, [locationData, locationLoading, locationError]);

	const onSubmitAddress = () => {
		if (searchAddress) {
			getLocationByAddress({ variables: { address: searchAddress } });
		}
	};

	const [errorModal, setErrorModal] = useState(false);
	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [driverSelectOptions, setDriverSelectOptions] = useState([]);

	const [
		getAdminsByAdmin,
		{ loading: adminsLazyLoading, error: adminsLazyError, data: adminsLazyData, fetchMore },
	] = useLazyQuery(GET_SHOP_ADMINS);

	useEffect(() => {
		if (adminsLazyData) {
			setDriverSelectOptions(
				Array.from(adminsLazyData.getAdminsByAdmin.filter((admin) => !admin.shop), (shopAdmin) => ({
					name: shopAdmin.fullName + ' - ' + shopAdmin.phoneNumber,
					value: shopAdmin._id,
				}))
			);
		}
	}, [, adminsLazyData, adminsLazyLoading]);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [search, setSearch] = useState('');

	useEffect(() => {
		const handler = setTimeout(() => {
			getAdminsByAdmin({
				variables: {
					limit: 10,
					skip: 0,
					type: 'SHOP-ADMIN',
					fullName: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	const onLoadMore = () => {
		fetchMore({
			query: GET_SHOP_ADMINS,
			variables: {
				limit: 10,
				skip: fetchMoreSkip,
				type: 'SHOP-ADMIN',
				fullName: search ? search : '',
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				// console.log('this is new results:', fetchMoreResult.getAdminsByAdmin);
				setDriverSelectOptions((driverSelectOptions) => [
					...driverSelectOptions,
					...Array.from(fetchMoreResult.getAdminsByAdmin, (driver) => ({
						name: driver.fullName + ' - ' + driver.phoneNumber,
						value: driver._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};
 
	const { loading, errors, data } = useQuery(GET_CATEGORIES, {
		variables: {
			parent: null,
		},
	});

	// console.log('errors: ', errors)
	// console.log('data: ', data)
	const [categoriesVariables, setCategoriesVariables] = useState({
		parent: undefined,
	});

	const [attributeGroupsVariables, setAttributeGroupsVariables] = useState({
		rootCategory: undefined,
	});

	const { loading: categoriesLoading, error: categoriesError, data: categoriesData } = useQuery(GET_CATEGORIES, {
		variables: categoriesVariables,
	});

	const { loading: attributeGroupsLoading, error: attributeGroupsError, data: attributeGroupsData } = useQuery(
		GET_ATTRIBUTE_GROUPS,
		{
			variables: attributeGroupsVariables,
		}
	);

	const budgetOptions = ['$', '$$', '$$$'];
	const budgetVariables = {
		$: 'B',
		$$: 'BB',
		$$$: 'BBB',
	};

	const weekDayOptions = [
		{
			name: 'Saturday',
			value: 'SAT',
		},
		{
			name: 'Sunday',
			value: 'SUN',
		},
		{
			name: 'Monday',
			value: 'MON',
		},
		{
			name: 'Tuesday',
			value: 'TUE',
		},
		{
			name: 'Wednesday',
			value: 'WEN',
		},
		{
			name: 'Thursday',
			value: 'THU',
		},
		{
			name: 'Friday',
			value: 'FRI',
		},
	];

	const [categoryIds, setCategoryIds] = useState(
		props.shop && props.shop.categories ? Array.from(props.shop.categories, (item) => item._id) : []
	);
	const [attributeIds, setAttributeIds] = useState(
		props.shop && props.shop.attributes ? Array.from(props.shop.attributes, (item) => item._id) : []
	);

	const rootCategoryAlt = { Restaurant: 'Restaurant', Purchase: 'Shop' };

	const [rootCategoryName, setRootCategoryName] = useState(props.shop ? props.shop.rootCategory.title : 'Restaurant');
	const [rootCategoryId, setRootCategoryId] = useState(
		props.shop ? props.shop.rootCategory._id : data && data.getCategories[0]._id
	);

	useEffect(() => {
		if (data && rootCategoryId) {
			setCategoriesVariables({
				...categoriesVariables,
				parent: rootCategoryId,
			});
		}
	}, [, data, categoriesData, rootCategoryId]);

	useEffect(() => {
		if (data && attributeGroupsData) {
			setAttributeGroupsVariables({
				...attributeGroupsVariables,
				rootCategory: rootCategoryId,
			});
		}
	}, [, data, attributeGroupsData, rootCategoryId]);

	useEffect(() => {
		if (data && (!props.shop || (props.shop && !props.shop.rootCategory))) {
			setRootCategoryName(data.getCategories[0].title);
		}
	}, [, data]);

	useEffect(() => {
		if (data && (!props.shop || (props.shop && !props.shop.rootCategory))) {
			setRootCategoryId(data.getCategories[0]._id);
		}
	}, [, data, loading]);

	useEffect(() => {
		if (data) {
			setRootCategoryId(data.getCategories.find((category) => category.title === rootCategoryName)._id);
		}
	}, [, rootCategoryName]);

	const [shopAdminId, setShopAdminId] = useState('');
	const [name, setName] = useState(props.shop ? props.shop.name : '');
	const [address, setAddress] = useState(props.shop ? props.shop.address : '');
	const [province, setProvince] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [streetAddress, setStreetAddress] = useState('');

	const [townError, setTownError] = useState(false);
	const [provinceError, setProvinceError] = useState(false);
	const [postalCodeError, setPostalCodeError] = useState(false);
	const [streetAddressError, setStreetAddressError] = useState(false);

	const [errorItems, setErrorItems] = useState([]);

	const [phoneNumber1, setPhoneNumber1] = useState(props.shop ? props.shop.phoneNumbers[0] : '');
	const [phoneNumber2, setPhoneNumber2] = useState(props.shop ? props.shop.phoneNumbers[1] : '');
	const [phoneNumber3, setPhoneNumber3] = useState(props.shop ? props.shop.phoneNumbers[2] : '');
	const [description, setDescription] = useState(props.shop ? props.shop.description : '');
	const [preparingTime, setPreparingTime] = useState(props.shop ? String(props.shop.preparingTime) : '');
	const [town, setTown] = useState(props.shop ? props.shop.origin : '');
	const [bannerImage, setbannerImage] = useState(props.shop && props.shop.bannerUrl ? props.shop.bannerUrl : '');
	const [logoImage, setLogoImage] = useState(props.shop && props.shop.logoUrl ? props.shop.logoUrl : '');
	const [spinnerIndex, setSpinnerIndex] = useState();
	const [budget, setBudget] = useState(
		props.shop && props.shop.budget
			? Object.keys(budgetVariables).find((item) => budgetVariables[item] === props.shop.budget)
			: budgetOptions[0]
	);
	const [active, setActive] = useState(props.shop ? props.shop.active : true);
	const [location, setLocation] = useState();
	const [offDays, setOffDays] = useState([]);
	const [workingHours, setWorkingHours] = useState({});

	const [errorText, setErrorText] = useState('');
	const [disableButton, setDisableButton] = useState(false);

	useEffect(() => {
		if (props.shop && props.shop.workingHoursInMinutes) {
			let object = {};
			props.shop.workingHoursInMinutes.map((item) => {
				object[item.type] = {
					from: moment('00:00', 'HH:mm')
						.startOf('day')
						.add(item.from, 'minutes'),
					to: moment('00:00', 'HH:mm')
						.startOf('day')
						.add(item.to, 'minutes'),
				};
			});
			setWorkingHours(object);
		}
	}, [,]);

	const onScrollDown = () => {
		downRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const onScrollTop = () => {
		topRef.current.scrollIntoView({ behavior: 'smooth' });
	};

	const [nameError, setnameError] = useState(false);
	const [descriptionError, setDescriptionError] = useState(false);
	const [preparingTimeError, setPreparingTimeError] = useState(false);
	const [shopAdminIdError, setShopAdminIdError] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [phoneNumber1Error, setPhoneNumber1Error] = useState(false);

	const [categoryLabelText, setCategoryLabelText] = useState('Cuisine');

	useEffect(() => {
		if (rootCategoryName === 'Restaurant') {
			setCategoryLabelText('Cuisine');
		} else {
			setCategoryLabelText('Shop type');
		}
	}, [, rootCategoryName]);

	const onMapClick = ({ x, y, lat, lng, event }) => {
		setmarkerPosition({ lat: lat, lng: lng });
		setLocation({ lat: lat, lng: lng });
	};

	const [phoneIndex, setPhoneIndex] = useState(props.shop ? props.shop.phoneNumbers.length : 1);

	useEffect(() => {
		let days = weekDayOptions.filter((weekDayOption) => !workingHours.hasOwnProperty(weekDayOption.value));
		setOffDays(Array.from(days, (day) => ({ type: day.value })));
	}, [workingHours]);

	const [workingHoursVariables, setWorkingHoursVariables] = useState();
	// props.shop && props.shop.workingHoursInMinutes
	//   ? props.shop.workingHoursInMinutes
	//   : []

	const midNight = moment('00:00', 'HH:mm');
	const t = moment('01:30', 'HH:mm');

	useEffect(() => {
		let array = [];
		Object.keys(workingHours).map((workingHour) =>
			array.push({
				type: workingHour.toUpperCase(),
				from: workingHours[workingHour].from,
				to: workingHours[workingHour].to,
			})
		);
		setWorkingHoursVariables(array);
	}, [workingHours]);

	const [createShopByShopAdmin] = useMutation(ADD_SHOP_BY_SHOP_ADMIN);

	const [updateShopByShopAdmin] = useMutation(UPDATE_SHOP_BY_SHOP_ADMIN);
	const [updateShopAfterRejectedByShopAdmin] = useMutation(UPDATE_SHOP_AFTER_REJECTION);

	const [createShopByAdmin, { data: addSuperData }] = useMutation(ADD_SHOP_BY_ADMIN);

	const [uploadFile, { data: uploadData, error: uploadError, loading: uploadLoading }] = useMutation(UPLOAD_FILE);

	const [getNewAccessTokenByAdmin, { data: tokenData, error: tokenError, loading: tokenLoading }] = useLazyQuery(
		GET_TOKEN
	);

	useEffect(() => {
		if (tokenData) {
			login(
				tokenData.getNewAccessTokenByAdmin.token.accessToken.toString(),
				tokenData.getNewAccessTokenByAdmin.token.refreshToken.toString()
			);
			window.location.href = endPoint + '/api/v1/paymentMethod';
		}
	}, [tokenData, tokenLoading]);

	if (tokenError) {
		console.log(tokenError);
	}

	const uploadBannerImage = (file) => {
		setSpinnerIndex(1);
		uploadFile({
			variables: { file: file.src.file, folderName: 'bannerImage' },
		}).then((res) => {
			// console.log(res.data.uploadFile.url);
			setbannerImage(res.data.uploadFile.url);
		});
	};

	const uploadLogoImage = (file) => {
		setSpinnerIndex(2);
		uploadFile({
			variables: { file: file.src.file, folderName: 'logoImage' },
		}).then((res) => {
			// console.log(res.data.uploadFile.url);
			setLogoImage(res.data.uploadFile.url);
		});
	};

	// block navigation
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [shouldLeavePage, setShouldLeavePage] = useState(false);

	useEffect(() => {
		if (shouldLeavePage) {
			window.onbeforeunload = undefined;
		} else {
			if (shouldBlockNavigation) {
				window.onbeforeunload = () => true;
			} else {
				window.onbeforeunload = undefined;
			}
		}
	}, [shouldBlockNavigation]);

	useEffect(() => {
		return () => {
			window.onbeforeunload = null;
		};
	}, []);

	// check for changes in edit and add form, to warn while leaving the page
	useEffect(() => {
		if (props.shop) {
			if (
				name === props.shop.name &&
				description === props.shop.description &&
				preparingTime == props.shop.preparingTime &&
				phoneNumber1 == props.shop.phoneNumbers[0] &&
				budget.length === props.shop.budget.length &&
				town === props.shop.origin &&
				address === props.shop.address &&
				((!props.shop.categories && categoryIds.length === 0) ||
					(props.shop.categories && props.shop.categories.length === categoryIds.length)) &&
				markerPosition.lat == props.shop.location.coordinates[1] &&
				markerPosition.lng == props.shop.location.coordinates[0] &&
				((!props.shop.logoUrl && !logoImage) ||
					(props.shop.logoUrl && logoImage && logoImage === props.shop.logoUrl)) &&
				((!props.shop.bannerUrl && !bannerImage) ||
					(props.shop.bannerUrl && bannerImage && bannerImage === props.shop.bannerUrl)) &&
				((!props.shop.phoneNumbers[1] && !phoneNumber2) ||
					(props.shop.phoneNumbers[1] && phoneNumber2 && phoneNumber2 == props.shop.phoneNumbers[1])) &&
				((!props.shop.phoneNumbers[2] && !phoneNumber3) ||
					(props.shop.phoneNumbers[2] && phoneNumber3 && phoneNumber3 == props.shop.phoneNumbers[2]))
			) {
				setShouldBlockNavigation(false);
			} else {
				setShouldBlockNavigation(true);
			}
		} else {
			if (
				name ||
				description ||
				preparingTime ||
				phoneNumber1 ||
				phoneNumber2 ||
				phoneNumber3 ||
				logoImage ||
				bannerImage ||
				town ||
				postalCode ||
				province ||
				streetAddress ||
				markerPosition.lat !== 43.6532 ||
				markerPosition.lng !== -79.3832
			) {
				setShouldBlockNavigation(true);
			} else {
				setShouldBlockNavigation(false);
			}
		}
	});

	const onCancel = () => {
		window.onbeforeunload = null;
		setShouldBlockNavigation(false);
		setShouldLeavePage(true);
		if (props.type === 'superAdmin') {
			window.location.href = endPoint + `/superAdmin/${props.shopName}/${props.id}/products`;
		} else {
			window.location.href = endPoint + '/shopAdmin/dashboard';
		}
	};

	const onSubmit = () => {
		if (!name) {
			setnameError(true);
			setErrorItems((errorItems) => [...errorItems, 'name']);
		} else {
			setnameError(false);
			setErrorItems((errorItems) => errorItems.filter((item) => item !== 'name'));
		}
		if (props.type === 'superAdmin' && !shopAdminId) {
			setShopAdminIdError(true);
			setErrorItems((errorItems) => [...errorItems, 'shop admin']);
		} else {
			setShopAdminIdError(false);
			setErrorItems((errorItems) => errorItems.filter((item) => item !== 'shop admin'));
		}
		if (!description) {
			setDescriptionError(true);
			setErrorItems((errorItems) => [...errorItems, 'description']);
		} else {
			setDescriptionError(false);
			setErrorItems((errorItems) => errorItems.filter((item) => item !== 'desctiption'));
		}
		if (props.type === 'superAdmin' && !address) {
			setAddressError(true);
			setErrorItems((errorItems) => [...errorItems, 'address']);
		} else {
			setAddressError(false);
			setErrorItems((errorItems) => errorItems.filter((item) => item !== 'address'));
		}

		if (!phoneNumber1) {
			setPhoneNumber1Error(true);
			setErrorItems((errorItems) => [...errorItems, 'phone number']);
		} else {
			setPhoneNumber1Error(false);
			setErrorItems((errorItems) => errorItems.filter((item) => item !== 'phone number'));
		}
		if (!preparingTime) {
			setPreparingTimeError("preparing time can't be empty");
			setErrorItems((errorItems) => [...errorItems, 'delivery preparing time']);
		} else {
			if (String(preparingTime).match(/^([1-9]|[1-5]\d|60|all)$/) && preparingTime >= 5) {
				setPreparingTimeError('');
				setErrorItems((errorItems) => errorItems.filter((item) => item !== 'delivery preparing time'));
			} else {
				setPreparingTimeError('enter number between 5 to 60');
				setErrorItems((errorItems) => [...errorItems, 'delivery preparing time']);
			}
		}
		if (!props.shop) {
			if (!postalCode) {
				setPostalCodeError(true);
				setErrorItems((errorItems) => [...errorItems, 'postal code']);
			} else {
				setPostalCodeError(false);
				setErrorItems((errorItems) => errorItems.filter((item) => item !== 'postal code'));
			}
			if (!streetAddress) {
				setStreetAddressError(true);
				setErrorItems((errorItems) => [...errorItems, 'street address']);
			} else {
				setStreetAddressError(false);
				setErrorItems((errorItems) => errorItems.filter((item) => item !== 'street address'));
			}
			if (!town) {
				setTownError(true);
				setErrorItems((errorItems) => [...errorItems, 'town']);
			} else {
				setTownError(false);
				setErrorItems((errorItems) => errorItems.filter((item) => item !== 'town'));
			}
			if (!province) {
				setProvinceError(true);
				setErrorItems((errorItems) => [...errorItems, 'province']);
			} else {
				setProvinceError(false);
				setErrorItems((errorItems) => errorItems.filter((item) => item !== 'province'));
			}
		}
		if (
			preparingTime &&
			String(preparingTime).match(/^([1-9]|[1-5]\d|60|all)$/) &&
			preparingTime >= 5 &&
			name &&
			phoneNumber1 &&
			town &&
			((props.shop && address) || (!props.shop && streetAddress && province && postalCode)) &&
			description
		) {
			setDisableButton(true);
			window.onbeforeunload = null;
			setShouldBlockNavigation(false);
			setShouldLeavePage(true);
			if (props.type === 'shopAdmin' && !props.shop) {
				createShopByShopAdmin({
					variables: {
						name: name.trim(),
						description: description.trim(),
						origin: town.trim(),
						address:
							postalCode.trim() + ' ' + streetAddress.trim() + ' ' + town.trim() + ' ' + province.trim(),
						preparingTime: parseInt(preparingTime),
						phoneNumbers: [phoneNumber1, phoneNumber2, phoneNumber3].filter(
							(item) => item && item !== null && item !== undefined
						),
						location: {
							coordinates: [parseFloat(markerPosition.lng), parseFloat(markerPosition.lat)],
							type: 'Point',
						},
						budget: budgetVariables[budget],
						active: true,
						bannerUrl: bannerImage,
						logoUrl: logoImage,
						rootCategory: rootCategoryId,
						categories: categoryIds,
						notWorkingDays: offDays,
						workingHoursInMinutes: Array.from(workingHoursVariables, (item) => ({
							type: item.type,
							from: item.from.diff(midNight, 'minutes'),
							to: item.to.diff(midNight, 'minutes'),
						})),
					},
				})
					.then((res) => {
						window.localStorage.setItem('shopId', res.data.createShopByShopAdmin._id.toString());
						window.localStorage.setItem(
							'shopType',
							res.data.createShopByShopAdmin.rootCategory.title.toString()
						);
						// console.log(res);
						getNewAccessTokenByAdmin({
							variables: {
								refreshToken: window.localStorage.getItem('refreshToken'),
							},
						});
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}

			if (props.type === 'shopAdmin' && props.shop && !props.editAfterRejection) {
				updateShopByShopAdmin({
					variables: {
						name: name.trim(),
						description: description.trim(),
						origin: town.trim(),
						address: address.trim(),
						preparingTime: parseInt(preparingTime),
						phoneNumbers: [phoneNumber1, phoneNumber2, phoneNumber3].filter(
							(item) => item && item !== null && item !== undefined
						),
						location: {
							coordinates: [parseFloat(markerPosition.lng), parseFloat(markerPosition.lat)],
							type: 'Point',
						},
						budget: budgetVariables[budget],
						active: active,
						bannerUrl: bannerImage,
						logoUrl: logoImage,
						rootCategory: rootCategoryId,
						categories: categoryIds,
						notWorkingDays: offDays,
						workingHoursInMinutes: Array.from(workingHoursVariables, (item) => ({
							type: item.type,
							from: item.from.diff(midNight, 'minutes'),
							to: item.to.diff(midNight, 'minutes'),
						})),
					},
				})
					.then((res) => {
						window.location.href = endPoint + '/shopAdmin/dashboard';
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}

			if (props.type === 'shopAdmin' && props.shop && props.editAfterRejection) {
				updateShopAfterRejectedByShopAdmin({
					variables: {
						name: name.trim(),
						description: description.trim(),
						origin: town.trim(),
						address: address.trim(),
						preparingTime: parseInt(preparingTime),
						phoneNumbers: [phoneNumber1, phoneNumber2, phoneNumber3].filter(
							(item) => item && item !== null && item !== undefined
						),
						location: {
							coordinates: [parseFloat(markerPosition.lng), parseFloat(markerPosition.lat)],
							type: 'Point',
						},
						budget: budgetVariables[budget],
						active: active,
						bannerUrl: bannerImage,
						logoUrl: logoImage,
						rootCategory: rootCategoryId,
						categories: categoryIds,
						notWorkingDays: offDays,
						workingHoursInMinutes: Array.from(workingHoursVariables, (item) => ({
							type: item.type,
							from: item.from.diff(midNight, 'minutes'),
							to: item.to.diff(midNight, 'minutes'),
						})),
					},
				})
					.then((res) => {
						window.localStorage.setItem(
							'shopType',
							res.data.updateShopAfterRejectedByShopAdmin.rootCategory.title.toString()
						);
						window.location.href = endPoint + window.localStorage.getItem('nextStep');
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}

			if (props.type === 'superAdmin' && shopAdminId) {
				createShopByAdmin({
					variables: {
						shopAdminId: shopAdminId.trim(),
						name: name.trim(),
						description: description.trim(),
						origin: town.trim(),
						address:
							postalCode.trim() + ' ' + streetAddress.trim() + ' ' + town.trim() + ' ' + province.trim(),
						preparingTime: parseInt(preparingTime),
						phoneNumbers: [phoneNumber1, phoneNumber2, phoneNumber3].filter(
							(item) => item && item !== null && item !== undefined
						),
						location: {
							coordinates: [parseFloat(markerPosition.lng), parseFloat(markerPosition.lat)],
							type: 'Point',
						},
						budget: budgetVariables[budget],
						bannerUrl: bannerImage,
						logoUrl: logoImage,
						rootCategory: rootCategoryId,
						notWorkingDays: offDays,
						workingHoursInMinutes: Array.from(workingHoursVariables, (item) => ({
							type: item.type,
							from: item.from.diff(midNight, 'minutes'),
							to: item.to.diff(midNight, 'minutes'),
						})),
					},
				})
					.then((res) => {
						window.location.href = endPoint + '/superAdmin/shops';
						// console.log(res);
					})
					.catch((err) => {
						setDisableButton(false);
						console.log(err);
						if (err.graphQLErrors && err.graphQLErrors.length > 0) {
							setErrorText(err.graphQLErrors[0].message);
							setErrorModal(true);
						}
					});
			}
		}
	};

	if (loading || categoriesLoading) {
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	}

	return (
		<div className={style.mainDiv}>
			<Prompt when={shouldBlockNavigation} message="You have unsaved changes, are you sure you want to leave?" />
			<div ref={topRef} className={style.scrollDownDiv}>
				<Button onClick={onScrollDown} title="scroll to down" round justIcon color="yellow">
					<KeyboardArrowDownIcon />
				</Button>
			</div>
			<div ref={downRef} className={style.scrollUpDiv}>
				<Button onClick={onScrollTop} title="scroll to top" round justIcon color="yellow">
					<KeyboardArrowUpIcon />
				</Button>
			</div>
			<div className={style.content}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={`${style.row} ${name ? style.colorfulRow : ''}`}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Shop name:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={name}
										onChange={(e) => setName(e.target.value)}
										error={nameError}
										labelText={nameError ? "name can't be empty*" : 'name'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						{props.type === 'superAdmin' && (
							<div className={`${style.selectSearchRow} ${shopAdminId ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5}>
										<div className={style.label}>Shop admin:</div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7}>
										{shopAdminIdError && (
											<div className={style.selectSearchError}>shop admin can't be empty*</div>
										)}
										<div>
											<SelectSearch
												options={driverSelectOptions}
												value={shopAdminId}
												onScroll={(e) => handleScroll(e)}
												getOptions={(searchWord) => {
													setSearch(searchWord);
												}}
												name=""
												search
												placeholder="Choose shop admin"
												onChange={(value) => setShopAdminId(value)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={`${style.row} ${description ? style.colorfulRow : ''}`}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Description:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										error={descriptionError}
										labelText={descriptionError ? "description can't be empty*" : 'description'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={`${style.row} ${preparingTime ? style.colorfulRow : ''}`}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Delivery preparing time:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={preparingTime}
										onChange={(e) => setPreparingTime(e.target.value)}
										error={preparingTimeError ? true : false}
										labelText={
											preparingTimeError
												? preparingTimeError
												: 'number between 5 to 60 (in minutes)'
										}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: 'number',
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						<div className={`${style.row} ${phoneNumber1 ? style.colorfulRow : ''}`}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>First phone number:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<CustomInput
										value={phoneNumber1}
										onChange={(e) => setPhoneNumber1(e.target.value)}
										error={phoneNumber1Error}
										labelText={phoneNumber1Error ? "phone number can't be empty*" : 'phone number'}
										id="float"
										formControlProps={{
											fullWidth: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
						{(phoneIndex === 2 || phoneIndex === 3) && (
							<div className={`${style.row} ${phoneNumber2 ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Second phone number:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={phoneNumber2}
											onChange={(e) => setPhoneNumber2(e.target.value)}
											labelText="2th phone number"
											id="float"
											inputProps={{
												disabled: !phoneNumber1 ? true : false,
											}}
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{phoneIndex === 3 && (
							<div className={`${style.row} ${phoneNumber3 ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Third phone number:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={phoneNumber3}
											onChange={(e) => setPhoneNumber3(e.target.value)}
											labelText="3th phone number"
											id="float"
											inputProps={{
												disabled: !phoneNumber2 || !phoneNumber1 ? true : false,
											}}
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.addPhone}>
							{((phoneIndex === 1 && phoneNumber1) || (phoneIndex === 2 && phoneNumber2)) && (
								<div
									className={style.addPhoneButton}
									onClick={() => setPhoneIndex((phoneIndex) => phoneIndex + 1)}
								>
									{' '}
									+ add new phone number
								</div>
							)}
						</div>

						<div className={style.dropdownRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.label}>Price range:</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<RadioGroup
										aria-label="budget"
										name="budget"
										value={budget}
										onChange={(e) => setBudget(e.target.value)}
									>
										<FormControlLabel value="$" control={<Radio color="default" />} label="$" />
										<FormControlLabel value="$$" control={<Radio color="default" />} label="$$" />
										<FormControlLabel value="$$$" control={<Radio color="default" />} label="$$$" />
									</RadioGroup>
								</GridItem>
							</GridContainer>
						</div>
						{!props.shop && data && (
							<div className={style.dropdownRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Select the function of shop:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<RadioGroup
											aria-label="shopType"
											name="shopType"
											value={rootCategoryName}
											onChange={(e) => {
												setRootCategoryName(e.target.value);
											}}
										>
											{data.getCategories.map((rootCategory) => (
												<FormControlLabel
													key={rootCategory._id}
													value={rootCategory.title}
													label={rootCategoryAlt[rootCategory.title]}
													control={<Radio color="default" />}
												/>
											))}
										</RadioGroup>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{categoriesData && (
							<div className={style.checkboxRow}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.checkboxLabel}>{categoryLabelText}:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<GridContainer>
											{categoriesData.getCategories.map((category) => (
												<GridItem key={category._id} xs={12} sm={6} md={6}>
													<FormControlLabel
														control={
															<Checkbox
																style={{ color: theme.checkboxColor }}
																color={theme.checkboxColor}
																name={category.title}
																checked={categoryIds.includes(category._id)}
																onChange={() => {
																	if (categoryIds.includes(category._id)) {
																		setCategoryIds(
																			categoryIds.filter(
																				(id) => id !== category._id
																			)
																		);
																	} else {
																		setCategoryIds([...categoryIds, category._id]);
																	}
																}}
															/>
														}
														label={category.title}
													/>
												</GridItem>
											))}
										</GridContainer>
									</GridItem>
								</GridContainer>
							</div>
						)}

						<div className={style.checkboxRow}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<div className={style.checkboxLabel} style={{ marginBottom: '15px' }}>
										Working days:
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									<div style={{ marginBottom: '15px', fontSize: '16px', fontWeight: '400' }}>
										<FormControlLabel
											label="Every day"
											control={
												<Checkbox
													color="default"
													name="every day"
													onChange={() => {
														if (Object.keys(workingHours).length === 7) {
															setWorkingHours({});
														} else {
															weekDayOptions.map((weekDay) => {
																if (!workingHours.hasOwnProperty(weekDay.value)) {
																	setWorkingHours((workingHours) => ({
																		...workingHours,
																		[weekDay.value]: {
																			from: moment('08:00', 'HH:mm'),
																			to: moment('23:00', 'HH:mm'),
																			error: false,
																		},
																	}));
																}
															});
														}
													}}
													checked={Object.keys(workingHours).length === 7}
												/>
											}
										/>
									</div>
								</GridItem>
								<GridItem xs={12} sm={12} md={12}>
									{weekDayOptions.map((weekDay) => (
										<div
											key={weekDay.name}
											className={`${
												!workingHours.hasOwnProperty(weekDay.value)
													? style.notSelectedDay
													: style.eachDay
											}`}
										>
											<div style={{ fontSize: '16px', fontWeight: '400' }}>
												<FormControlLabel
													label={weekDay.name}
													control={
														<Checkbox
															color="default"
															name={weekDay.name}
															checked={workingHours.hasOwnProperty(weekDay.value)}
															onChange={() => {
																if (workingHours.hasOwnProperty(weekDay.value)) {
																	const {
																		[weekDay.value]: {},
																		...rest
																	} = workingHours;
																	setWorkingHours(rest);
																} else {
																	setWorkingHours({
																		...workingHours,
																		[weekDay.value]: {
																			from: moment('08:00', 'HH:mm'),
																			to: moment('23:00', 'HH:mm'),
																			error: false,
																		},
																	});
																}
															}}
														/>
													}
												/>
											</div>

											<GridContainer>
												{workingHours.hasOwnProperty(weekDay.value) && (
													<GridItem xs={12} sm={6} md={6}>
														<div className={style.dateRow}>
															<GridContainer>
																<GridItem xs={12} sm={6} md={5}>
																	<div className={style.dateLabel}>Start hour:</div>
																</GridItem>
																<GridItem xs={12} sm={6} md={7}>
																	<Datetime
																		timeFormat={'HH:mm'}
																		timeConstraints={{
																			hours: {
																				min: 0,
																				max: 22,
																			},
																			minutes: { min: 0, max: 59, step: 1 },
																		}}
																		dateFormat={false}
																		disableOnClickOutside
																		value={workingHours[weekDay.value].from}
																		closeOnSelect
																		onChange={(date) => {
																			if (
																				moment(date, 'HH:mm').isBefore(
																					moment(
																						workingHours[weekDay.value].to,
																						'HH:mm'
																					)
																				)
																			) {
																				const w_h = {
																					...workingHours,
																					[weekDay.value]: {
																						...workingHours[weekDay.value],
																						from: moment(date, 'HH:mm'),
																					},
																				};
																				setWorkingHours(w_h);
																			}

																			// setWorkingHours({
																			//   ...workingHours,
																			//   [weekDay.value]: {
																			//     ...workingHours[weekDay.value],
																			//     from: date,
																			//   },
																			// })
																		}}
																		inputProps={{
																			placeholder: 'set start hour',
																			readOnly: true,
																		}}
																	/>
																</GridItem>
															</GridContainer>
														</div>
													</GridItem>
												)}
												{workingHours.hasOwnProperty(weekDay.value) && (
													<GridItem xs={12} sm={6} md={6}>
														<div className={style.dateRow}>
															<GridContainer>
																<GridItem xs={12} sm={6} md={5}>
																	<div className={style.dateLabel}>End hour:</div>
																</GridItem>
																<GridItem xs={12} sm={6} md={7}>
																	<Datetime
																		dateFormat={false}
																		timeFormat={'HH:mm'}
																		disableOnClickOutside
																		timeConstraints={{
																			hours: {
																				min: 1,
																				max: 23,
																			},
																			minutes: { min: 0, max: 59, step: 1 },
																		}}
																		value={workingHours[weekDay.value].to}
																		closeOnSelect
																		onChange={(date) => {
																			if (
																				moment(date, 'HH:mm').isAfter(
																					moment(
																						workingHours[weekDay.value]
																							.from,
																						'HH:mm'
																					)
																				)
																			) {
																				setWorkingHours({
																					...workingHours,
																					[weekDay.value]: {
																						...workingHours[weekDay.value],
																						to: moment(date, 'HH:mm'),
																					},
																				});
																			}
																		}}
																		inputProps={{
																			placeholder: 'set end hour',
																			readOnly: true,
																		}}
																	/>
																</GridItem>
															</GridContainer>
														</div>
													</GridItem>
												)}
											</GridContainer>
										</div>
									))}
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.mapRow}>
							<GridContainer>
								<GridItem xs={12} sm={4} md={4}>
									<div className={style.mapLabel}>Location:</div>
								</GridItem>
								<GridItem xs={12} sm={8} md={8}>
									{!showLocationInputs && (
										<div className={style.locationErrorDiv}>select a location*</div>
									)}
									<div className={style.mapContainer}>
										<FunMap
											setCity={(city) => setTown(city)}
											city={town}
											setAddress={(address) => setAddress(address)}
											setStreet={(street) => setStreetAddress(street)}
											setProvince={(province) => setProvince(province)}
											onSetmarkerPosition={(lat, lng) => setmarkerPosition({ lat, lng })}
											setPostalCode={(postalCode) => setPostalCode(postalCode)}
											position={{
												lat: markerPosition.lat,
												lng: markerPosition.lng,
											}}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>

						{props.shop && showLocationInputs && (
							<div className={`${style.row} ${address ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Address:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={address}
											onChange={(e) => setAddress(e.target.value)}
											error={addressError}
											labelText={addressError ? "address can't be empty*" : 'address'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}

						{!props.shop && showLocationInputs && (
							<div className={`${style.row} ${postalCode ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Postal code:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={postalCode}
											onChange={(e) => setPostalCode(e.target.value)}
											error={postalCodeError}
											labelText={postalCodeError ? "postal code can't be empty*" : 'postal code'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						{!props.shop && showLocationInputs && (
							<div className={`${style.row} ${streetAddress ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Street address:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={streetAddress}
											onChange={(e) => setStreetAddress(e.target.value)}
											error={streetAddressError}
											labelText={
												streetAddressError ? "street address can't be empty*" : 'street address'
											}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}

						{showLocationInputs && (
							<div className={`${style.row} ${town ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Town/City:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={town}
											onChange={(e) => setTown(e.target.value)}
											error={townError}
											labelText={townError ? "town/city can't be empty*" : 'town/city'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}

						{!props.shop && showLocationInputs && (
							<div className={`${style.row} ${province ? style.colorfulRow : ''}`}>
								<GridContainer>
									<GridItem xs={12} sm={6} md={5}>
										<div className={style.label}>Province:</div>
									</GridItem>
									<GridItem xs={12} sm={6} md={7}>
										<CustomInput
											value={province}
											onChange={(e) => setProvince(e.target.value)}
											error={provinceError}
											labelText={provinceError ? "province can't be empty*" : 'province'}
											id="float"
											formControlProps={{
												fullWidth: true,
											}}
										/>
									</GridItem>
								</GridContainer>
							</div>
						)}
						<div className={style.imageRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.imageLabel}>
										Shop sign:{' '}
										<div className={style.imageHint}>(upload an image less than 10MB)</div>
									</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.imageContainer}>
										<Files
											maxSize="2mb"
											multiple={false}
											convertToBase64={true}
											accept={['image/jpg', 'image/jpeg', 'image/png']}
											onSuccess={(file) => uploadBannerImage(file[0])}
										>
											{({ browseFiles, getDropZoneProps, getLabelProps }) => (
												<div style={{ textAlign: 'left', margin: '15px 0' }}>
													{bannerImage && (
														<div>
															<img
																style={{ width: '200px', height: '200px' }}
																alt="banner image"
																src={`${graphqlUrl}${bannerImage}`}
															/>
														</div>
													)}
													{uploadLoading && spinnerIndex === 1 && (
														<CircularProgress style={{ color: 'gray' }} />
													)}
													<div {...getDropZoneProps({ className: 'myDropZone' })} />

													<Button onClick={browseFiles} color="whiteButton">
														{bannerImage ? 'Change image' : '+ Select image'}
													</Button>
												</div>
											)}
										</Files>
									</div>
								</GridItem>
							</GridContainer>
						</div>
						<div className={style.imageRow}>
							<GridContainer>
								<GridItem xs={12} sm={6} md={5}>
									<div className={style.imageLabel}>
										Logo image:{' '}
										<div className={style.imageHint}>(upload an image less than 10MB)</div>
									</div>
								</GridItem>
								<GridItem xs={12} sm={6} md={7}>
									<div className={style.imageContainer}>
										<Files
											maxSize="2mb"
											multiple={false}
											convertToBase64={true}
											accept={['image/jpg', 'image/jpeg', 'image/png']}
											onSuccess={(file) => uploadLogoImage(file[0])}
										>
											{({ browseFiles, getDropZoneProps, getLabelProps }) => (
												<div style={{ textAlign: 'left' }}>
													{logoImage && (
														<div>
															<img
																style={{ width: '200px', height: '200px' }}
																alt="logo image"
																src={`${graphqlUrl}${logoImage}`}
															/>
														</div>
													)}
													{uploadLoading && spinnerIndex === 2 && (
														<CircularProgress style={{ color: 'gray' }} />
													)}
													<div {...getDropZoneProps({ className: 'myDropZone' })} />

													<Button onClick={browseFiles} color="whiteButton">
														{logoImage ? 'Change image' : '+ Select image'}
													</Button>
												</div>
											)}
										</Files>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<div className={style.buttonContainer}>
				{(props.shop || props.type === 'superAdmin') && (
					<Button onClick={onCancel} color="whiteButton">
						Cancel
					</Button>
				)}
				<Button disabled={uploadLoading || disableButton} onClick={onSubmit} color={theme.primaryColor}>
					Submit
				</Button>
			</div>
			{errorItems.length > 0 && (
				<div className={style.wholeError}>check {[...new Set(errorItems)].join(' , ')} input</div>
			)}
			<Dialog open={showRejectionMessage} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal close={() => setShowRejectionMessage(false)} type="notif" text={showRejectionMessageText} />
				</DialogContent>
			</Dialog>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default AddShop;
