import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  MarkerWithLabel,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
import { useMutation, useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import CustomMarker from "commonComponents/addShop/Marker";
import topCar from "assets/img/top-car.png";
import originIcon from "assets/img/originIcon.png";
import style from "./OrderDetails.module.scss";

Geocode.setApiKey("AIzaSyAQVDinbS7etW3HRiY4DQil6Zsbww76Vx8");
Geocode.enableDebug();

const UPDATE_CAR_LOCATION = gql`
  subscription tripCarLocation($trackId: String) {
    tripCarLocation(trackId: $trackId) {
      long
      lat
      angle
    }
  }
`;

const iconMarker = new window.google.maps.MarkerImage(
  topCar,
  null /* size is determined at runtime */,
  null /* origin is 0,0 */,
  null /* anchor is bottom center of the scaled image */,
  new window.google.maps.Size(20, 32)
);

const iconOriginMarker = new window.google.maps.MarkerImage(
  originIcon,
  null /* size is determined at runtime */,
  null /* origin is 0,0 */,
  null /* anchor is bottom center of the scaled image */,
  new window.google.maps.Size(20, 32)
);

const AsyncMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      google={props.google}
      defaultZoom={15}
      defaultCenter={{
        lat: props.position.lat,
        lng: props.position.lng,
      }}
    >
      <Marker
      visible={props.visible}
        icon={iconMarker}
        google={props.google}
        labelClass={style.mapIcon}
        name={"Dolores park"}
        position={{
          lat: props.position.lat,
          lng: props.position.lng,
        }}
      />
      <Marker /> 
      
      <Marker
        google={props.google}
        name={"Dolores park"}
        position={{
          lat: props.destination.lat,
          lng: props.destination.lng,
        }}
      />

      <Marker />
    <Marker
        icon={iconOriginMarker}
        google={props.google}
        name={"Dolores park"}
        position={{
          lat: props.origin.lat,
          lng: props.origin.lng,
        }}
      />

      <Marker /> 
    </GoogleMap>
  ))
);

const FunOrderMap = (props) => {
  const [position, setPosition] = useState({
    lat: props.position.lat,
    lng: props.position.lng,
  });

  const {
    data: updateLocationData,
    error: updateLocationError,
    loading: updateLocationLoading,
  } = useSubscription(UPDATE_CAR_LOCATION, {
    variables: { trackId: props.trackId },
  });

  useEffect(() => {
    if (updateLocationData) {
      setPosition({
        lat: updateLocationData.tripCarLocation.lat,
        lng: updateLocationData.tripCarLocation.long,
      });
    }
  }, [updateLocationData, updateLocationLoading]);

  return (
    <div>
      <AsyncMap
      visible={updateLocationData ? true : false}
        position={position}
        destination={props.destination}
        origin={props.origin}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAQVDinbS7etW3HRiY4DQil6Zsbww76Vx8&libraries=places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "250px" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default FunOrderMap;
